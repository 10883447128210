import { createCart, fetchCart } from "api/woo/fetch-cart";
import { AxiosError } from "axios";
import { useRouter } from "next/router";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Cart } from "types/cart";
import { CART_TOKEN } from "utils/constants";
import { getRegionFromLocale, setCheckoutApiHeaders } from "utils/helpers";

interface CartConsumerProps {
  cart: Cart | null;
  token: string | null;
  refresh: (data: Cart) => void;
  clear: () => void;
  loading: boolean;
}

interface CartProviderProps {
  children: JSX.Element;
}

const CartContext = createContext<CartConsumerProps>({
  cart: null,
  token: null,
  refresh: () => undefined,
  clear: () => undefined,
  loading: true,
});

/**
 * Provider for cart context, handles cart data, including tokens
 * used for Woo API POST requests
 */
export const CartProvider = ({ children }: CartProviderProps) => {
  const router = useRouter();

  const [cart, setCart] = useState<Cart | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  // Set new cart data
  const refreshCart = (data: Cart) => {
    setCart(data);
  };

  // Set new token in local storage and state
  const refreshToken = useCallback(
    (token: string) => {
      // Set new token in local storage and context
      setToken(token);
      localStorage.setItem(CART_TOKEN, token);
      // Set checkout API headers
      setCheckoutApiHeaders({
        token,
        storeLocation: getRegionFromLocale(router.locale),
      });
    },
    [router.locale]
  );

  // Get cart data
  const fetchCartWithToken = useCallback(async () => {
    try {
      const res = await fetchCart();
      refreshToken(res.cart_token);
      setCart(res);
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 400 || err.response?.status === 500) {
        // If token is invalid, create new cart based on store location
        try {
          // Get store location
          const storeLocation = getRegionFromLocale(router.locale);
          // Create new cart
          const res = await createCart(storeLocation);
          refreshToken(res.cart_token);
          setCart(res);
        } catch (error) {
          console.error(error);
        }
      } else {
        console.error(error);
      }
    }
  }, [refreshToken]);

  // Remove token for cart + remove it from Local Storage
  const clearCart = () => {
    setLoading(true);

    localStorage.removeItem(CART_TOKEN);
    setToken(null);

    // Set checkout API default headers
    setCheckoutApiHeaders({
      token: null,
      storeLocation: getRegionFromLocale(router.locale),
    });

    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    // On mount, check if  token is in Local Storage
    const tokenFromStorage = localStorage.getItem(CART_TOKEN);
    // Get store location
    const storeLocation = getRegionFromLocale(router.locale);

    // Set store location and token if exists
    setCheckoutApiHeaders({
      token: tokenFromStorage,
      storeLocation: getRegionFromLocale(router.locale),
    });

    if (tokenFromStorage) {
      // If token exists, fetch cart data
      fetchCartWithToken().finally(() => setLoading(false));
    } else {
      // If token doesn't exist, create new cart
      createCart(storeLocation)
        .then((res) => {
          refreshToken(res.cart_token);
          setCart(res);
        })
        .catch((error) => console.error(error))
        .finally(() => setLoading(false));
    }
  }, [fetchCartWithToken, refreshToken, router.locale]);

  return (
    <CartContext.Provider
      value={{
        cart,
        token,
        refresh: refreshCart,
        clear: clearCart,
        loading,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
