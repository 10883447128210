import MessageSnackbar, {
  SnackbarType,
} from "@/components/common/MessageSnackbar";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as Sentry from "@sentry/react";

interface SnackbarMessage {
  message: string;
  type?: SnackbarType;
  key?: number;
}

export interface SnackbarConsumerProps {
  pushAlert: (alert: SnackbarMessage) => void;
  pushError: (message: string) => void;
}

export const SnackbarContext = createContext<SnackbarConsumerProps>({
  pushAlert: () => undefined,
  pushError: () => undefined,
});

export const SnackbarProvider = ({ children }: { children: JSX.Element }) => {
  const [alerts, setAlerts] = useState<SnackbarMessage[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [currentAlert, setCurrentAlert] = useState<SnackbarMessage | undefined>(
    undefined
  );

  useEffect(() => {
    if (alerts.length && !currentAlert) {
      setCurrentAlert({ ...alerts[0] });
      setAlerts((prev) => prev.slice(1));
      setOpen(true);
    } else if (alerts.length && currentAlert && open) {
      setOpen(false);
    }
  }, [alerts, currentAlert, open]);

  const pushAlert = useCallback((alert: SnackbarMessage) => {
    setAlerts((alerts) => [
      ...alerts,
      { message: alert.message, type: alert.type, key: new Date().getTime() },
    ]);
  }, []);

  const pushError = useCallback((message: string) => {
    setAlerts((alerts) => [
      ...alerts,
      { message: message, type: SnackbarType.Error, key: new Date().getTime() },
    ]);
    Sentry.captureException(new Error(message));
  }, []);

  const handleExited = useCallback(() => {
    setCurrentAlert(undefined);
  }, []);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const providerValues: SnackbarConsumerProps = useMemo(
    () => ({ pushAlert, pushError }),
    [pushAlert, pushError]
  );

  return (
    <SnackbarContext.Provider value={providerValues}>
      {children}
      <MessageSnackbar
        open={open}
        key={currentAlert ? currentAlert.key : undefined}
        onClose={handleClose}
        message={currentAlert ? currentAlert.message : undefined}
        type={currentAlert?.type ?? undefined}
        transition={{ onExited: handleExited }}
      />
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => useContext(SnackbarContext);
