import { createTheme } from "@mui/material/styles";
import { Montserrat } from '@next/font/google'

const montserrat = Montserrat({ subsets: ['latin'] })

const theme = createTheme({
  typography: {
    fontFamily: montserrat.style.fontFamily,
    h1: {
      color: "#393c41",
      fontWeight: 600,
      fontSize: 40,
    },
    h2: {
      color: "black",
      fontWeight: 600,
      fontSize: 30,
    },
    h3: {
      fontWeight: 600,
      fontSize: 20,
    },
    h4: {
      fontSize: 15,
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: 13,
    },
    subtitle2: {
      fontWeight: 500,
      color: "#5c5e62",
      fontSize: 13,
    },
    body1: {
      fontWeight: 500,
      fontSize: 14,
    },
    body2: {
      fontWeight: 500,
      fontSize: 20,
    },
  },

  palette: {
    primary: {
      main: "#3e6ae1",
    },
    error: {
      main: "#C62828",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          ":hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          padding: "8px 20px",
          fontSize: 14,
          fontWeight: 600,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          padding: "0px 0px 0px 20px",
          fontSize: 12,
          fontWeight: 500,
        },
      },
    },
  },
});

export default theme;
