import { WooStore } from "types/regions";

// Each region has different WooCommerce API URL
// All regions all stored here
export const STORE_REGIONS: WooStore[] = [
  { code: "eu", url: "/wp-json/wc/store/v1" },
  { code: "us", url: "/usa/wp-json/wc/store/v1" },
];

// Default store region
export const DEFAULT_STORE_REGION = {
  code: "eu",
  url: "/wp-json/wc/store/v1",
};

// Supported languages
export const LANGUAGES = ["en"];

export interface Path<T> {
  params: T;
  locale: string;
}
