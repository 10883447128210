import { ButtonUnstyled, ButtonUnstyledProps } from "@mui/base";
import { styled } from "@mui/system";

const DrawerItemButton = (props: ButtonUnstyledProps): JSX.Element => {
  const DrawerItemButtonRoot = styled("button")`
    width: 100%;
    color: inherit;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
    padding: 10px 15px 10px 15px;
    display: flex;
    border: none;
    border-radius: 12px;
    transition: background-color 150ms ease-out 150ms;

    &:hover {
      background-color: hsla(0, 0%, 0%, 0.05);
    }

    &:focus {
      outline: none;
      outline-offset: 2px;
    }
  `;
  return <ButtonUnstyled {...props} component={DrawerItemButtonRoot} />;
};

export default DrawerItemButton;
