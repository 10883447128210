import "../styles/globals.css";
import * as React from "react";
import Head from "next/head";
import { AppProps } from "next/app";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { CacheProvider, EmotionCache } from "@emotion/react";

import theme from "utils/theme";
import { SnackbarProvider } from "providers/SnackbarProvider";

import "../styles.css";
import MainLayout from "@/components/layouts/MainLayout";
import { LocalizationProvider } from "providers/LocalizationProvider";
import { CartProvider } from "providers/CartProvider";
import { GlobalProvider } from "providers/GlobalProvider";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import createEmotionCache from "../createEmotionCache";
import { useRouter } from "next/router";
import { Montserrat } from "@next/font/google";
import Script from "next/script";
import { GTM_ID, pageview } from "utils/analytics/ga/gtm";
import { useEffect } from "react";
// Analytics
import * as fbq from "../utils/analytics/fb_pixel/fpixel";
import * as gtag from "../utils/analytics/ga/gtm";
import { FB_PIXEL_ID } from "../utils/analytics/fb_pixel/fpixel";
// Posthog
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { logPosthogEvent } from "utils/analytics/posthog/events";
import { getURL } from "utils/helpers";

const montserrat = Montserrat({ subsets: ["latin"] });

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const PAYPAL_CLIENT_ID = process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID;

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== "undefined" && process.env.NEXT_PUBLIC_POSTHOG_KEY) {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: `${getURL()}ingest`,
    persistence: "localStorage",
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug();
    },
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually
  });
}

function MyApp(props: MyAppProps) {
  const router = useRouter();
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  // Used for Google Analytics, fire pageview event on route change
  useEffect(() => {
    router.events.on("routeChangeComplete", pageview);
    return () => {
      router.events.off("routeChangeComplete", pageview);
    };
  }, [router.events]);

  // FB Pixel code
  // Included as documented here https://github.com/vercel/next.js/tree/canary/examples/with-facebook-pixel
  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageview();

    const handleRouteChange = (url: string) => {
      fbq.pageview();
      gtag.pageview(url);
      logPosthogEvent(posthog, "$pageview", undefined);
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  // PayPal currency based on detected locale
  const paypalCurrency = router.locale === "en-us" ? "USD" : "EUR";

  useEffect(() => {
    if (getCookieConsentValue() === "true") {
      // If user has already accepted cookies, grant consent
      gtag.grantConsent();
    }
  }, []);

  return (
    <>
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}

          gtag('consent', 'default', {
              'ad_storage': 'denied',
              'analytics_storage': 'denied',
              'ad_user_data': 'denied',
              'ad_personalization': 'denied'
          });

          gtag('js', new Date());
 
          gtag('config', '${GTM_ID}');
        `}
      </Script>
      {/* Google Tag Manager - Global base code */}
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GTM_ID}');`}
      </Script>

      {/* Global Site Code Pixel - Facebook Pixel */}
      {/* Included as documented here https://github.com/vercel/next.js/tree/canary/examples/with-facebook-pixel */}
      {FB_PIXEL_ID && (
        <Script
          id="fb-pixel"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
          }}
        />
      )}
      <PostHogProvider>
        <PayPalScriptProvider
          options={{
            "client-id": PAYPAL_CLIENT_ID ?? "",
            "disable-funding": "credit,card",
            currency: paypalCurrency,
          }}
        >
          <SnackbarProvider>
            <CartProvider>
              <LocalizationProvider>
                <GlobalProvider>
                  <CacheProvider value={emotionCache}>
                    <main className={montserrat.className}>
                      <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Head>
                          <meta
                            name="viewport"
                            content="width=device-width, initial-scale=1, maximum-scale=1"
                          />
                        </Head>
                        <MainLayout>
                          <Component {...pageProps} />
                          <CookieConsent
                            onAccept={() => gtag.grantConsent()}
                            location="bottom"
                            buttonStyle={{
                              width: "150px",
                              background: "rgba(23, 26, 32, 0.8)",
                              color: "white",
                              borderRadius: "100px",
                              padding: "13px",
                              fontSize: "12px",
                              fontWeight: 600,
                            }}
                            style={{
                              paddingLeft: "10px",
                              paddingRight: "30px",
                            }}
                            buttonText="Accept"
                          >
                            We use cookies on our website to give you the most
                            relevant experience by remembering your preferences
                            and repeat visits. By using our website you consent
                            to the use of all the cookies.
                          </CookieConsent>
                        </MainLayout>
                      </ThemeProvider>
                    </main>
                  </CacheProvider>
                </GlobalProvider>
              </LocalizationProvider>
            </CartProvider>
          </SnackbarProvider>
        </PayPalScriptProvider>
      </PostHogProvider>
    </>
  );
}

export default MyApp;
