import { ICarModel } from "interfaces/api_interfaces";

/* Payments redirect relative paths */
export const PAYMENT_SUCCESS_URL = "/order-completed";
export const PAYMENT_FAILURE_URL = "/shop/cart";

/* Names in local storage */
export const CART_TOKEN = "cart-token";

// Category ID name for add-on products from Woocommerce on configurator page
export const CONFIGURATOR_ADDON_PRODUCT_CATEGORY_EU = "41";
export const CONFIGURATOR_ADDON_PRODUCT_CATEGORY_US = "17";
// Category ID name for shop products
export const SHOP_PRODUCT_CATEGORY_EU = "20";
export const SHOP_PRODUCT_CATEGORY_US = "16";
// Configurator default car
export const CONFIGURATOR_DEFAULT_CAR: ICarModel = {
  id: 2,
  name: "Tesla Model S",
  brand_name: "Tesla",
};

export const SNACKBAR_HIDE_DURATION = 2000;

// Static site generation invalidation time in seconds.
export const REVALIDATE_TIME = 5 * 60; // 5 minutes

export const US_COUNTRIES = { US: "United States (US)" };

export const ERR_SHIPPING = "ERR_UNAVAILABLE_SHIPPING";
// Default country for billing and shipping address
export const DEFAULT_EU_BILLING_COUNTRY = "DE";
export const DEFAULT_WORLD_BILLING_COUNTRY = "US";
