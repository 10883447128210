import { Alert, Snackbar, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { SNACKBAR_HIDE_DURATION } from "utils/constants";

export enum VerticalPosition {
  Top = "top",
  Bottom = "bottom",
}

export enum HorizontalPosition {
  Left = "left",
  Right = "right",
}

export enum SnackbarType {
  Success = "success",
  Error = "error",
}

interface Props {
  open: boolean;
  message: string | undefined;
  onClose: (event: React.SyntheticEvent | Event, reason?: string) => void;
  transition: TransitionProps | undefined;
  vertical?: VerticalPosition;
  horizontal?: HorizontalPosition;
  type?: SnackbarType;
  snackbarKey?: number;
}

const MessageSnackbar = ({
  open,
  message,
  onClose,
  transition,
  vertical = VerticalPosition.Top,
  horizontal = HorizontalPosition.Right,
  type = SnackbarType.Success,
  snackbarKey,
}: Props): JSX.Element => {
  return (
    <Snackbar
      key={snackbarKey ?? undefined}
      open={open}
      autoHideDuration={SNACKBAR_HIDE_DURATION}
      message={message}
      anchorOrigin={{ vertical, horizontal }}
      onClose={onClose}
      TransitionProps={transition}
    >
      <Alert onClose={onClose} severity={type} sx={{ width: "100%" }}>
        <Typography variant="body1">{message}</Typography>
      </Alert>
    </Snackbar>
  );
};

export default MessageSnackbar;
