import { Cart } from "types/cart";
import { GaEcommerceEvent, GaPurchaseEvent } from "./interfaces";

// Needed for GTM
declare const window: Window & { gtag: any };

export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
export const GOOGLE_VERIFICATION_ID =
  process.env.NEXT_PUBLIC_GOOGLE_VERIFICATION_ID;

export const pageview = (url: string) => {
  window.gtag("config", GTM_ID, {
    page_path: url,
  });
};

export const addToCart = (data: GaEcommerceEvent) => {
  window.gtag("event", "add_to_cart", data);
};

export const initiateCheckout = (cart: Cart) => {
  const data = {
    value: Number(cart.totals.total_price) / 100,
    currency: cart.totals.currency_code,
    items: cart.items.map((item) => ({
      item_id: item.id.toString(),
      quantity: item.quantity,
    })),
  };

  window.gtag("event", "begin_checkout", data);
};

export const purchase = (cart: Cart) => {
  const data: GaPurchaseEvent = {
    transaction_id: crypto.randomUUID(),
    value: Number(cart.totals.total_price) / 100,
    currency: cart.totals.currency_code,
    items: cart.items.map((item) => ({
      item_id: item.id.toString(),
      quantity: item.quantity,
    })),
  };

  window.gtag("event", "purchase", data);
};

export const grantConsent = () => {
  window.gtag("consent", "update", {
    ad_storage: "granted",
    analytics_storage: "granted",
    ad_user_data: "granted",
    ad_personalization: "granted",
  });
};
