import { Stack } from "@mui/material";
import MaterialLink from "@/components/common/MaterialLink";
import { useLocalization } from "providers/LocalizationProvider";
import { useGlobalState } from "providers/GlobalProvider";

type Props = {
  absolute?: boolean;
};

const Footer = ({ absolute = false }: Props): JSX.Element => {
  const { getLocalizedString, toLocalizedString } = useLocalization();
  const { colors } = useGlobalState();
  const { menu } = useGlobalState();

  const footerMenu = menu?.filter((item) => item.show_in_footer === true);

  return (
    <>
      <Stack
        direction={{ xs: "column", lg: "row" }}
        color={colors.nav}
        spacing={{ xs: 2, md: 3 }}
        sx={{
          position: absolute ? "absolute" : "relative",
          bottom: 0,
          width: 1,
          py: 3,
          justifyContent: "center",
          textAlign: "center",
          fontSize: 10,
        }}
      >
        <MaterialLink
          fontWeight={500}
          underline="none"
          href="https://dreamcase.eu"
          color="inherit"
        >
          {getLocalizedString("appName")} © {new Date().getFullYear()}
        </MaterialLink>
        {footerMenu?.map((item) => {
          const { id, name, page } = item;
          return (
            <MaterialLink
              key={id}
              underline="none"
              color="inherit"
              fontWeight="600"
              href={page ?? ""}
            >
              {toLocalizedString(name)}
            </MaterialLink>
          );
        })}
        <MaterialLink
          fontWeight={500}
          underline="none"
          href="https://www.zerodays.dev/"
          color="inherit"
        >
          Made by zerodays
        </MaterialLink>
      </Stack>
    </>
  );
};

export default Footer;
