import { AppBar, Box, styled, Toolbar, useScrollTrigger } from "@mui/material";

import {
  buttonUnstyledClasses,
  TabsListUnstyled,
  TabsUnstyled,
  TabUnstyled,
} from "@mui/base";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
//import LanguageIcon from "@mui/icons-material/Language";
import { useState } from "react";
import MobileDrawer from "./MobileDrawer";
import { KeyboardEvent, MouseEvent } from "react";
import { useLocalization } from "providers/LocalizationProvider";
import dreamcaseLogoBlack from "../../public/dreamcase_logo_black.svg";
import dreamcaseLogoWhite from "../../public/dreamcase_logo_white.svg";

import Image from "next/legacy/image";
import { useRouter } from "next/router";
import { IMenuEntry } from "interfaces/api_interfaces";
import { useGlobalState } from "providers/GlobalProvider";

export enum NavColor {
  Dark = "white",
  Light = "black",
}

interface Props {
  menuItems?: IMenuEntry[];
  hideNavItems?: boolean;
  shop?: boolean;
  whiteBackgroundOnScroll?: boolean;
}

const Navbar = ({
                  menuItems,
                  hideNavItems = false,
                  shop = false,
                  whiteBackgroundOnScroll = false,
                }: Props): JSX.Element => {
  const {getLocalizedString, toLocalizedString} = useLocalization();
  const {colors} = useGlobalState();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const router = useRouter();

  // Menu items in main menu
  const mainMenuItems = menuItems?.filter(
      (item) => item.show_in_main_menu === true
  );

  const scrollTrigger = useScrollTrigger({
    threshold: 0,
    disableHysteresis: true,
  });

  const toggleDrawer =
      (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
        if (
            event.type === "keydown" &&
            ((event as KeyboardEvent).key === "Tab" ||
                (event as KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        setOpenDrawer(open);
      };

  const Tab = styled(TabUnstyled)`
    color: ${!scrollTrigger ? colors.nav : NavColor.Light};
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    background: transparent;
    padding: 10px 15px 10px 15px;
    margin: 6px 6px;
    border: none;
    border-radius: 12px;
    transition: background-color 150ms ease-out 150ms;

    &:hover {
      background-color: hsla(0, 0%, 0%, 0.05);
    }

    &:focus {
      outline: none;
      outline-offset: 2px;
    }

    &.${buttonUnstyledClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `;

  const TabsList = styled(TabsListUnstyled)`
    display: flex;
  `;

  const logoColorBlack = colors.nav === NavColor.Light || scrollTrigger;

  const navbarBackground =
      colors.navBackground === NavColor.Light ? "transparent" : "white";

  return (
      <AppBar
          position="fixed"
          elevation={0}
          sx={whiteBackgroundOnScroll ? {
            transition: "background-color 250ms ease-out",
          } : {backgroundColor: navbarBackground}}
          color={scrollTrigger ? "inherit" : "transparent"}
      >
        <Toolbar sx={{display: "flex", justifyContent: "space-between"}}>
          <Box>
            <Box
                sx={{
                  cursor: "pointer",
                  width: 170,
                  padding: "10px 15px 10px 15px",
                  margin: "6px 6px",
                }}
            >
              <Image
                  src={logoColorBlack ? dreamcaseLogoBlack : dreamcaseLogoWhite}
                  alt="DreamCase Logo"
                  layout="responsive"
                  onClick={() => router.push("/")}
              />
            </Box>
          </Box>
          <Box
              sx={{
                flexGrow: 1,
                display: {xs: "none", lg: "flex"},
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
          >
            {mainMenuItems && !hideNavItems && (
                <TabsUnstyled defaultValue={0}>
                  <TabsList>
                    {mainMenuItems.map((item) => {
                      const {id, name, page} = item;
                      return page ? (
                          <Tab key={id} onClick={() => router.push(page)}>
                            {toLocalizedString(name)}
                          </Tab>
                      ) : null;
                    })}
                  </TabsList>
                </TabsUnstyled>
            )}
          </Box>
          <Box>
            <TabsUnstyled defaultValue={0}>
              <TabsList>
                <Tab
                    sx={{
                      display: {xs: "none", md: "block"},
                    }}
                    onClick={() => router.push("/shop/cart")}
                >
                  <ShoppingCartIcon fontSize="small"/>
                </Tab>
                {!shop && (
                    <Tab
                        sx={{display: {xs: "none", md: "block"}}}
                        onClick={() => router.push("/shop")}
                    >
                      {getLocalizedString("shopTitle")}
                    </Tab>
                )}
                <Tab onClick={toggleDrawer(true)}>
                  {getLocalizedString("menu")}
                </Tab>
              </TabsList>
            </TabsUnstyled>
          </Box>
        </Toolbar>
        <MobileDrawer
            open={openDrawer}
            menuItems={menuItems}
            toggleDrawer={toggleDrawer(false)}
        />
      </AppBar>
  );
};

export default Navbar;
