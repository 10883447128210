import { PostHog } from "posthog-js";

type POSTHOG_EVENTS = {
  $pageview: undefined;

  configurator_opened: undefined;

  configurator_changed: undefined;

  configurator_addons_removed: undefined;

  configurator_car_selected: {
    carId: number;
    carBrand: string;
    carName: string;
  };

  add_to_cart_configurator: {
    productId: string;
    productName: string;
  };

  add_to_cart_shop: {
    productId: string;
    productName: string;
    quantity: number;
  };

  enter_billing_shipping_details: {
    country: string;
  };

  select_shipping_method: {
    shippingMethodId: string;
  };

  confirm_shipping_method: {
    shippingMethodId: string;
  };

  checkout_agree_with_terms: {
    agreed: boolean;
  };

  paypal_button_clicked: undefined;

  credit_card_button_clicked: undefined;

  order_completed: undefined;
};

export const logPosthogEvent = <T extends keyof POSTHOG_EVENTS>(
  posthog: PostHog,
  event: T,
  // If POSTHOG_EVENTS[T] is undefined, then properties should be undefined, else required
  properties: POSTHOG_EVENTS[T]
) => {
  posthog.capture(event, { ...properties });
};
