import axios from "axios";

const CHECKOUT_API_URL = process.env.NEXT_PUBLIC_CHECKOUT_API_URL;

/**
 * Axios instance for Checkout API
 */
const checkoutApi = axios.create({
  baseURL: `${CHECKOUT_API_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default checkoutApi;
