import { Box } from "@mui/material";
import { useRouter } from "next/router";
import { useGlobalState } from "providers/GlobalProvider";
import { ReactNode } from "react";
import Footer from "../common/Footer";
import Navbar, { NavColor } from "../common/Navbar";

export interface LayoutProps {
  children: ReactNode;
}

const MainLayout = ({ children }: LayoutProps) => {
  const router = useRouter();
  const { menu, colors } = useGlobalState();

  const isShop = router.pathname === "/shop";

  // Menu items in navbar
  const navMenu = menu?.filter(
    (item) => item.show_in_sidebar === true || item.show_in_main_menu === true
  );

  /*
  Hide global footer on pages with snapscroll container.
  Snap scroll container contains its own footer.
  */
  const hideFooter =
    router.pathname.startsWith("/product") ||
    router.pathname === "/configurator" ||
    router.pathname === "/" ||
    router.pathname === "/about";

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Navbar
        menuItems={navMenu}
        shop={isShop}
        whiteBackgroundOnScroll={
          isShop || colors.navBackground === NavColor.Light
        }
      />
      <Box flex={1}>{children}</Box>
      {!hideFooter && <Footer />}
    </Box>
  );
};

export default MainLayout;
