import axios from "axios";
import { DEFAULT_STORE_REGION } from "utils/regions_new";

const WOO_API_URL = process.env.NEXT_PUBLIC_WOO_API_URL;

/**
 * Axios instance for WooCommerce API
 */
const wooApi = axios.create({
  baseURL: `${WOO_API_URL}${DEFAULT_STORE_REGION.url}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default wooApi;
