import axios from "axios";

const API_URL = process.env.NEXT_PUBLIC_API_HOST;

const publicApi = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const vatApi = axios.create({
  baseURL: "/api/vat-validate",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export { publicApi, vatApi };
