
import { ILocalization } from "../interfaces/api_interfaces";

const strings: ILocalization = {
  "404": {
    "en": "404",
    "sl": "404"
  },
  "appName": {
    "en": "Dreamcase",
    "sl": "Dreamcase"
  },
  "companyName": {
    "en": "Company name",
    "sl": "Ime podjetja"
  },
  "configurator": {
    "en": "Configurator",
    "sl": "Konfigurator"
  },
  "selectedCar": {
    "en": "Selected car",
    "sl": "Izbrano vozilo"
  },
  "continueToCheckout": {
    "en": "Continue to Checkout",
    "sl": "Na blagajno"
  },
  "customOrder": {
    "en": "Custom order",
    "sl": "Naročilo po meri"
  },
  "existingInventory": {
    "en": "Existing inventory",
    "sl": "Zaloga"
  },
  "menu": {
    "en": "Menu",
    "sl": "Meni"
  },
  "addToCart": {
    "en": "Add to cart",
    "sl": "Dodaj v košarico"
  },
  "quantity": {
    "en": "Quantity",
    "sl": "Količina"
  },
  "remove": {
    "en": "Remove",
    "sl": "Odstrani"
  },
  "checkout": {
    "en": "Checkout",
    "sl": "Oddaj naročilo"
  },
  "subtotal": {
    "en": "Subtotal",
    "sl": "Skupaj"
  },
  "orderSummary": {
    "en": "Order Summary",
    "sl": "Povzetek naročila"
  },
  "tax": {
    "en": "Tax",
    "sl": "Davek"
  },
  "addedToCart": {
    "en": "Added to cart",
    "sl": "Dodano v košarico"
  },
  "error": {
    "en": "Error",
    "sl": "Napaka"
  },
  "lastName": {
    "en": "Last name",
    "sl": "Priimek"
  },
  "firstName": {
    "en": "First name",
    "sl": "Ime"
  },
  "phone": {
    "en": "Phone",
    "sl": "Telefon"
  },
  "postalCode": {
    "en": "Postal code",
    "sl": "Poštna številka"
  },
  "city": {
    "en": "City",
    "sl": "Kraj"
  },
  "country": {
    "en": "Country",
    "sl": "Država"
  },
  "step": {
    "en": "Step",
    "sl": "Korak"
  },
  "shipping": {
    "en": "Shipping",
    "sl": "Dostava"
  },
  "emailFormDesc": {
    "en": "Enter your email address to receive notifications for this order.",
    "sl": "Vnesite svoje e-poštni naslov, da boste obveščeni o tem naročilu"
  },
  "next": {
    "en": "Next",
    "sl": "Naprej"
  },
  "of": {
    "en": "of",
    "sl": "od"
  },
  "summaryAndPayment": {
    "en": "Summary and Payment",
    "sl": "Povzetek naročila in plačilo"
  },
  "summaryAndPaymentDesc": {
    "en": "Review summary of your order and submit payment.",
    "sl": "Preverite povzetek in plačajte naročilo."
  },
  "shippingFormDesc": {
    "en": "Choose preferred shipping from options.",
    "sl": "Izberite željeno vrsto dostave"
  },
  "availableCarModels": {
    "en": "Available car models",
    "sl": "Podprta vozila"
  },
  "errConfigNotFound": {
    "en": "Configurator not found!",
    "sl": "Konfigurator za ta model ne obstaja!"
  },
  "shopTitle": {
    "en": "Shop",
    "sl": "Trgovina"
  },
  "shopSubtitle": {
    "en": "Order now for fastest delivery",
    "sl": "Naročite zdaj za hitro dostavo"
  },
  "emptyCart": {
    "en": "Cart is empty",
    "sl": "Košarica je prazna"
  },
  "scrollToConfigurate": {
    "en": "Scroll down to configurate",
    "sl": "Premaknite navzdol za konfiguracijo"
  },
  "extraOptions": {
    "en": "Extra Options",
    "sl": "Dodatki"
  },
  "color": {
    "en": "Color",
    "sl": "Barva"
  },
  "addonAdded": {
    "en": "Add-on added",
    "sl": "Dodatek dodan"
  },
  "selectAddonDesc": {
    "en": "You can customize your Dreamcase with extras.",
    "sl": "Dodajte dodatke za boljšo izkušnjo."
  },
  "yourConfiguration": {
    "en": "Your configuration",
    "sl": "Vaša konfiguracija"
  },
  "address": {
    "en": "Address",
    "sl": "Naslov"
  },
  "days": {
    "en": "days",
    "sl": "dni"
  },
  "billing": {
    "en": "Billing",
    "sl": "Naslov za račun"
  },
  "checkoutAddressDesc": {
    "en": "Enter your shipping and billing address below.",
    "sl": "Vnesite naslov za pošiljanje in račun."
  },
  "state": {
    "en": "State / Province",
    "sl": "Zvezna država / Regija"
  },
  "fieldRequired": {
    "en": "This field is required",
    "sl": "Polje je obvezno"
  },
  "billingAddress": {
    "en": "Billing Address",
    "sl": "Naslov za račun"
  },
  "shippingAddress": {
    "en": "Shipping Address",
    "sl": "Naslov za pošiljanje"
  },
  "total": {
    "en": "Total",
    "sl": "Skupaj"
  },
  "orderCompleted": {
    "en": "Order Completed",
    "sl": "Naročilo oddano"
  },
  "paymentNotAvailable": {
    "en": "Payment not available",
    "sl": "Plačilo ni možno"
  },
  "backHome": {
    "en": "Back Home",
    "sl": "Nazaj domov"
  },
  "transactionFailed": {
    "en": "Unable to process transaction",
    "sl": "Transakcija ni bila uspešna"
  },
  "shippingNotSelected": {
    "en": "Shipping option is not selected",
    "sl": "Izberite vrsto dostave"
  },
  "businessBuyer": {
    "en": "Business Buyer",
    "sl": "Nakup na podjetje"
  },
  "vatNumber": {
    "en": "VAT number",
    "sl": "Davčna številka"
  },
  "optional": {
    "en": "optional",
    "sl": "izbirno"
  },
  "shippingSameAsBilling": {
    "en": "Shipping address is the same as billing",
    "sl": "Naslov za pošiljanje je enak naslovu za pošiljanje"
  },
  "cart": {
    "en": "Cart",
    "sl": "Košarica"
  },
  "calculatedAtCheckout": {
    "en": "Calculated at checkout",
    "sl": "Izračunano v naslednjem koraku"
  },
  "yourCartEmpty": {
    "en": "Your cart is empty.",
    "sl": "Vaša košarica je prazna."
  },
  "continueShopping": {
    "en": "Continue shopping",
    "sl": "Nazaj v trgovino"
  },
  "errConfigurationNotAvailable": {
    "en": "Configuration not available!",
    "sl": "Konfiguracija ni na voljo!"
  },
  "noPaymentSelected": {
    "en": "Payment option is not selected.",
    "sl": "Izberite vrsto plačila."
  },
  "error_vat_invalid": {
    "en": "VAT number is invalid."
  },
  "error_incorrect_address": {
    "en": "Address is not correct. Check entered fields.",
    "sl": "Naslov ni pravilen. Preverite vnešene podatke."
  },
  "couldNotBeShipped": {
    "en": "Items in cart could not be shipped to entered address.",
    "sl": "Izdelki ne morejo biti poslani na izbran naslov."
  },
  "errInvalidPhone": {
    "en": "Invalid phone number.",
    "sl": "Nepravilna telefonska številka."
  },
  "orderYourDreamcase": {
    "en": "Order your DreamCase"
  },
  "errPostalCode": {
    "en": "Invalid postal code.",
    "sl": "Napačna poštna številka."
  },
  "errQuantityUpdate": {
    "en": "Cannot set quantity.",
    "sl": "Količine ni mogoče nastaviti"
  },
  "quantityUpdated": {
    "en": "Quantity updated.",
    "sl": "Količina uspešno spremenjena."
  },
  "pageNotFound": {
    "en": "Page not found",
    "sl": "Stran ni bila najdena"
  },
  "purchaseConfirmedDesc": {
    "en": "Your purchase has been confirmed and emailed to you.",
    "sl": "Vašo naročilo je bilo potrjeno."
  },
  "moreImages": {
    "en": "More images",
    "sl": "Več slik"
  },
  "error_fetching_cart": {
    "en": "Error while loading cart."
  },
  "error_add_to_cart": {
    "en": "Error adding to cart."
  },
  "error_remove_from_cart": {
    "en": "Error while removing from cart."
  },
  "error_update_cart": {
    "en": "Error while updating cart."
  },
  "description": {
    "en": "Description"
  },
  "email": {
    "en": "Email"
  },
  "error_vat_validation": {
    "en": "Validating VAT ID failed."
  },
  "error_mail": {
    "en": "Error while adding email."
  },
  "error_incorrect_mail": {
    "en": "Incorrect e-mail address"
  },
  "error_shipping_method": {
    "en": "Error while updating shipping method."
  },
  "error_fetching_countries": {
    "en": "Error while loading country list."
  },
  "error_create_checkout": {
    "en": "Error while creating order."
  },
  "customizeYourDreamcase": {
    "en": "Customize your Dreamcase"
  },
  "error_adding_extra_option": {
    "en": "Error while adding extra option."
  },
  "error_unsupported_country": {
    "en": "We currently can't ship to selected country."
  },
  "selectCarDesc": {
    "en": "First, select your car below in the dropdown.",
    "sl": "Najprej, izberite svoje vozilo."
  },
  "wantDreamcaseFor": {
    "en": "I want Dreamcase for:"
  },
  "agreeToTerms": {
    "en": "I have read and agree to the website Terms and conditions & Privacy Policy."
  },
  "including": {
    "en": "Incudes"
  },
  "taxNotice": {
    "en": "Prices listed do not include Value Added Tax (VAT), which may be applicable during checkout."
  },
  "stateNotApplicable": {
    "en": "State is not applicable for the selected country."
  },
  "purchaseConfirmedShipping": {
    "en": "Over the next 24 hours your Dreamcase will begin its journey in handmade fashion along our production line. Estimated shipping date is within 1-3 weeks depending on stock & production. Shipping process is 2-5 days for most of the world & we use automated tracking codes via email so you can follow your DreamCase to your door."
  },
  "checkoutNotice": {
    "en": "Notice: All countries outside EU will be subject to local VAT import fees upon delivery."
  },
  "car": {
    "en": "Car"
  },
  "shopSeoTitle": {
    "en": "Premium Tesla Accessories"
  },
  "shopSeoDescription": {
    "en": "Transform your Tesla into a luxurious Pod-House-On-Wheels. Experience spontaneous, convenient, and safe travels with our premium Tesla accessories."
  },
  "shopName": {
    "en": "Premium Tesla Accessories | DreamCase",
    "sl": "Premium Tesla Accessories | DreamCase"
  },
  "shopSeoImageAlt": {
    "en": "Tesla premium accessories"
  },
  "shopSeoMetaUrl": {
    "en": "https://dreamcase.eu/shop/"
  },
  "confirm_and_pay": {
    "en": "Order with Card"
  },
  "otherCars": {
    "en": "Other cars:"
  },
  "goBackToConfigurator": {
    "en": "Configurator"
  },
  "seeGallery": {
    "en": "Gallery"
  },
  "gallery": {
    "en": "Gallery",
    "sl": "Galerija"
  }
};
export default strings;
