// FB Pixel code
// Included as documented here https://github.com/vercel/next.js/tree/canary/examples/with-facebook-pixel
import { Cart } from "types/cart";
import { PixelAddToCartEvent, PixelEvent } from "./interfaces";

export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

export const pageview = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.fbq("track", "PageView");
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name: any, options = {}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.fbq("track", name, options);
};

// When user add item to cart
export const addToCart = (data: PixelAddToCartEvent) => {
  event("AddToCart", data);
};

// When user enters checkout flow (i.e. enters )
export const initiateCheckout = (cart: Cart) => {
  const data: PixelEvent = {
    content_ids: cart.items.map((item) => item.id.toString()),
    value: Number(cart.totals.total_price) / 100,
    currency: cart.totals.currency_code,
    contents: cart.items.map((item) => ({
      id: item.id.toString(),
      quantity: item.quantity,
    })),
  };

  event("InitiateCheckout", data);
};

// When user completes checkout
export const purchase = (cart: Cart) => {
  const data: PixelEvent = {
    content_ids: cart.items.map((item) => item.id.toString()),
    value: Number(cart.totals.total_price) / 100,
    currency: cart.totals.currency_code,
    contents: cart.items.map((item) => ({
      id: item.id.toString(),
      quantity: item.quantity,
    })),
  };

  event("Purchase", data);
};
