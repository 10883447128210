import { ILocalizedString } from "interfaces/api_interfaces";
import { useRouter } from "next/router";
import { createContext, useContext, useEffect } from "react";
import localizedStrings from "./strings";
import { setWooStoreUrl } from "utils/helpers";

/* Context interfaces */
interface LocalizationConsumerProps {
  getLocalizedString: (key: string) => string;
  toLocalizedString: (value: ILocalizedString) => string;
}

interface LocalizationProviderProps {
  children: JSX.Element;
}

/* Context */
export const LocalizationContext = createContext<LocalizationConsumerProps>({
  getLocalizedString: () => "",
  toLocalizedString: () => "",
});

/* Provider for localization */
export const LocalizationProvider = ({
  children,
}: LocalizationProviderProps) => {
  const router = useRouter();

  const getLocalizedString = (key: string) => {
    if (localizedStrings[key] && router.locale) {
      const splitted = router.locale?.split("-");
      return localizedStrings[key][splitted[0]];
    }
    return "";
  };

  const toLocalizedString = (value: ILocalizedString) => {
    if (router.locale) {
      const splitted = router.locale?.split("-");
      if (value[splitted[0]]) return value[splitted[0]];
    }
    return "";
  };

  useEffect(() => {
    if (router.isReady) {
      // Set Woocommerce store url based on router locale
      setWooStoreUrl(router.locale);
    }
  }, [router.isReady, router.locale]);

  const providerValues: LocalizationConsumerProps = {
    getLocalizedString,
    toLocalizedString,
  };

  return (
    <LocalizationContext.Provider value={providerValues}>
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocalization = () => useContext(LocalizationContext);
