import checkoutApi from "api/checkout-client";
import wooApi from "api/woo-api-client";
import { AxiosRequestHeaders } from "axios";
import { IAttribute } from "providers/ConfiguratorProvider";
import {
  ProductVariation,
  ProductVariationAttribute,
  SelectedAttrTerm,
} from "types/product";
import { DEFAULT_STORE_REGION, STORE_REGIONS } from "./regions_new";
import { PageSectionAction } from "../interfaces/api_interfaces";
import { WooError } from "types/errors";
import { Cart } from "types/cart";

/**
 * Format price returned from WooCommerce API
 *
 * @param price price returned from WooCommerce API as string
 * @param currency_code currency code as string (format ISO 4217 - code)
 * @param locale Locale for formatting decimal and thousands separators
 * @returns Formatted price
 */
export const formatWooPrice = (
  price: string | null,
  currency_code: string,
  locale = "de-DE"
) => {
  const numAmount = Number(price) / 100;

  return Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    style: "currency",
    currency: currency_code,
  }).format(numAmount);
};

/**
 * Get prices from cart totals (used for cart/checkout totals calculation).
 *
 * @param cart Cart data
 */
export const getPrices = (cart: Cart) => {
  const {
    totals: {
      total_price,
      total_tax,
      total_shipping,
      total_items,
      total_items_tax,
      total_shipping_tax,
    },
    billing_address,
  } = cart;

  const subtotalPrice = Number(total_items) + Number(total_items_tax);

  // EU company with valid VAT number
  if (billing_address.country !== "SI" && billing_address.vat_number) {
    // Total price without vat
    const subtotalWithoutTax = Math.round(subtotalPrice / 1.22);
    const shippingWithoutTax = Number(total_shipping);

    return {
      subtotal_price: subtotalWithoutTax.toString(),
      shipping: shippingWithoutTax.toString(),
      total_price: (subtotalWithoutTax + shippingWithoutTax).toString(),
      total_tax: "0",
    };
  }

  return {
    subtotal_price: subtotalPrice.toString(),
    shipping: (Number(total_shipping) + Number(total_shipping_tax)).toString(),
    total_price: total_price.toString(),
    total_tax: total_tax.toString(),
  };
};

/**
 * Get WooCommerce store API URL by region code
 *
 * @param country Country code
 * @returns WooCommerce store API URL
 */
export const getWooStoreUrl = (country: string): string => {
  const wooBaseUrl = process.env.NEXT_PUBLIC_WOO_API_URL;

  const storeRegion = STORE_REGIONS.find((region) => region.code === country);

  return `${wooBaseUrl}${storeRegion?.url ?? DEFAULT_STORE_REGION.url}`;
};

/**
 * Get region for WooCommerce store form Next.js locale
 *
 * @param locale Next.js locale as string (format: <language_code>-<country-code>)
 * @returns region for WooCommerce store
 */
export const getRegionFromLocale = (locale: string | undefined): string => {
  if (!locale) {
    return DEFAULT_STORE_REGION.code;
  }

  const region = locale.split("-")[1];
  return region || DEFAULT_STORE_REGION.code;
};

/**
 * Set WooCommerce store API URL on Axios based on Next.js locale
 *
 * @param locale Next.js locale as string (format: <language_code>-<country-code>)
 */
export const setWooStoreUrl = (locale: string | undefined) => {
  const region = getRegionFromLocale(locale);
  wooApi.defaults.baseURL = getWooStoreUrl(region);
};

/**
 * Set global headers required by Checkout API
 *
 * @param {{token: string, storeLocation: string}} headers token used to identify cart, location of store
 */
export const setCheckoutApiHeaders = (headers: {
  token: string | null;
  storeLocation: string;
}) => {
  const { token, storeLocation } = headers;
  const requestHeaders: AxiosRequestHeaders = {};

  requestHeaders["store-location"] = storeLocation.toUpperCase();

  if (token) {
    // If token is provided, use it to identify cart/checkout
    requestHeaders["cart-token"] = token;
  }

  checkoutApi.defaults.headers.common = requestHeaders;
};

export const getConfiguration = (data: {
  variations: IAttribute;
  configuratorId: number;
}) => {
  const { variations, configuratorId } = data;
  const finalConfig = [];
  for (const [, value] of Object.entries(variations)) {
    finalConfig.push(value.id);
  }
  return { configuration_id: configuratorId, variations: finalConfig };
};

/**
 * Get variation ID from selected attribute terms array
 *
 * @param attributes Array of selected attribute terms
 * @param variations Array of available product variations
 */
export const variationIdFromAttributes = (
  attributes: SelectedAttrTerm,
  variations: ProductVariation[]
): string | undefined => {
  // Translate array to array of attribute terms for API
  const productVariation: ProductVariationAttribute[] = Object.keys(attributes)
    .sort()
    .map((e) => ({ name: e, value: attributes[e].term.slug }));

  return variations.find(
    (variation) =>
      JSON.stringify(variation.attributes) === JSON.stringify(productVariation)
  )?.id;
};

export const getPageSectionActionUrlFromLocale = (
  pageSectionAction: PageSectionAction,
  locale: string | undefined
) => {
  if (
    locale != null &&
    locale === "en-us" &&
    pageSectionAction.url_usa &&
    pageSectionAction.url_usa.length > 0
  ) {
    return pageSectionAction.url_usa;
  }
  return pageSectionAction.url;
};

// Create slug from product name
export const slugFromName = (name: string) => {
  return name.toLowerCase().replace(/ /g, "-");
};

// Check if error is WooError
export const isWooError = (error: any): error is WooError => {
  return (
    (error as WooError).error !== undefined &&
    (error as WooError).metadata !== undefined
  );
};

// Get redirect URL based on the environment
export const getURL = () => {
  let url =
    process?.env?.NEXT_PUBLIC_SITE_URL ?? // Set this to your site URL in production env.
    process?.env?.NEXT_PUBLIC_VERCEL_URL ?? // Automatically set by Vercel.
    "http://localhost:3000/";
  // Make sure to include `https://` when not localhost.
  url = url.includes("http") ? url : `https://${url}`;
  // Make sure to including trailing `/`.
  url = url.charAt(url.length - 1) === "/" ? url : `${url}/`;
  return url;
};
