import checkoutApi from "api/checkout-client";
import { Cart } from "types/cart";
import {
  DEFAULT_EU_BILLING_COUNTRY,
  DEFAULT_WORLD_BILLING_COUNTRY,
} from "utils/constants";

/**
 * Get cart data by token (global in wooApi instance)
 *
 * @returns Cart data
 */
const fetchCart = async (): Promise<Cart> => {
  try {
    const res = await checkoutApi.get("/cart");

    return res.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Create new cart and get new cart token
 *
 * @returns Cart data
 */
const createCart = async (storeLocation: string): Promise<Cart> => {
  try {
    // Get bililng country based on store location
    const billingCountry =
      storeLocation === "eu"
        ? DEFAULT_EU_BILLING_COUNTRY
        : DEFAULT_WORLD_BILLING_COUNTRY;

    // Create new cart
    const { data: newCart } = await checkoutApi.post("/cart");
    // Also set default billing address country (for tax purposes) after creating cart
    const cartWithCountry = await checkoutApi.put(
      "/cart/customer",
      {
        billing_address: {
          country: billingCountry,
        },
      },
      {
        headers: {
          "cart-token": newCart.cart_token,
        },
      }
    );

    return cartWithCountry.data;
  } catch (error) {
    throw error;
  }
};

export { fetchCart, createCart };
