import { Box, Drawer, IconButton, List, ListItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { KeyboardEvent, MouseEvent } from "react";
import DrawerItemButton from "../buttons/DrawerItemButton";
import { useLocalization } from "providers/LocalizationProvider";
import { useRouter } from "next/router";
import { IMenuEntry } from "interfaces/api_interfaces";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

interface Props {
  open: boolean;
  menuItems?: IMenuEntry[];
  toggleDrawer: (event: KeyboardEvent | MouseEvent) => void;
}

const MobileDrawer = ({
  open,
  menuItems,
  toggleDrawer,
}: Props): JSX.Element => {
  const { toLocalizedString, getLocalizedString } = useLocalization();
  const router = useRouter();

  return (
    <Drawer anchor="right" open={open} onClose={toggleDrawer}>
      <Box
        sx={{
          width: 300,
          display: "flex",
          flexDirection: "column",
        }}
        onClick={toggleDrawer}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "12px",
          }}
          mt={2}
        >
          <IconButton onClick={toggleDrawer}>
            <CloseIcon sx={{ color: "black" }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
          mt={2}
        >
          <List>
            <ListItem sx={{ display: { sx: "block", md: "none" } }}>
              <DrawerItemButton onClick={() => router.push("/shop/cart")}>
                <ShoppingCartIcon />
              </DrawerItemButton>
            </ListItem>
            <ListItem sx={{ display: { sx: "block", md: "none" } }}>
              <DrawerItemButton onClick={() => router.push(`/shop`)}>
                {getLocalizedString("shopTitle")}
              </DrawerItemButton>
            </ListItem>
            {menuItems?.map((item) => {
              const { id, name, page, show_in_main_menu, show_in_sidebar } =
                item;
              // On mobile show all from main menu, hide on desktop if show_in_sidebar is not set
              if (show_in_main_menu && !show_in_sidebar) {
                return (
                  <ListItem
                    sx={{ display: { sx: "block", md: "none" } }}
                    key={id}
                  >
                    <DrawerItemButton onClick={() => router.push(page ?? "")}>
                      {toLocalizedString(name)}
                    </DrawerItemButton>
                  </ListItem>
                );
              }

              return (
                <ListItem key={id}>
                  <DrawerItemButton onClick={() => router.push(page ?? "")}>
                    {toLocalizedString(name)}
                  </DrawerItemButton>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Box>
    </Drawer>
  );
};

export default MobileDrawer;
