import { NavColor } from "@/components/common/Navbar";
import { publicApi } from "api/content-api-client";
import { IMenuEntry } from "interfaces/api_interfaces";
import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useState } from "react";

export type Colors = {
  nav: NavColor;
  navBackground: NavColor;
  scroll: NavColor;
};
interface GlobalConsumerProps {
  menu: IMenuEntry[];
  loading: boolean;
  colors: Colors;
  setColors: (colors: Colors) => void;
}

const GlobalContext = createContext<GlobalConsumerProps>({
  menu: [],
  loading: false,
  colors: {
    nav: NavColor.Light,
    navBackground: NavColor.Light,
    scroll: NavColor.Dark,
  },
  setColors: () => undefined,
});

/**
 * Global provider for fetching menu
 *
 */
export const GlobalProvider = ({ children }: { children: JSX.Element }) => {
  const router = useRouter();
  const [menu, setMenu] = useState<IMenuEntry[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [colors, setColors] = useState<Colors>({
    nav: NavColor.Light,
    navBackground: NavColor.Light,
    scroll: NavColor.Dark,
  });

  const doNotResetNavColor =
    router.pathname === "/" ||
    router.pathname === "/product/[id]" ||
    router.pathname === "/about";

  useEffect(() => {
    // Reset nav color if route doesnt include inverted colors
    if (
      !doNotResetNavColor &&
      (colors.nav === NavColor.Dark ||
        colors.scroll === NavColor.Dark ||
        colors.navBackground === NavColor.Dark)
    ) {
      setColors({
        nav: NavColor.Light,
        navBackground: NavColor.Light,
        scroll: NavColor.Light,
      });
    }
  }, [colors.nav, colors.navBackground, colors.scroll, doNotResetNavColor]);

  useEffect(() => {
    setLoading(true);
    publicApi
      .get<IMenuEntry[]>("/menu")
      .then((res) => {
        setMenu(res.data);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  return (
    <GlobalContext.Provider value={{ menu, loading, colors, setColors }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalState = () => useContext(GlobalContext);
